import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session"; // defaults to localStorage for web, change to session

import analyse, {
  setTotalData,
  setScope,
  setSourceType,
  setDeviceData,
  setSelectedDate,
  setCompareFabs,
  setCompareYear,
} from "./AnalyseSlice";
import user, {
  setUser,
  changeUserCompany,
  setUserAuthorizationPages,
  setUserType,
  setToken,
  setSiteUser,
  setAuthFunctionList,
  setAuthUserViewList,
} from "./userSlice";
import project, {
  getLCA,
  clearLCA,
  changeLCAStatusToVerify,
  changeRiskStatusToVerify,
} from "./projectSlice";
import pageInfo, {
  setAntdLangage,
  setBarMenu,
  fetchLangByKey,
} from "./pageInfoSlice";

import sbtiPackage, {
  getSBTi,
  setBaseYearLCA,
} from "./sbtiPackageSlice";

import source, {
  setDataSource,
  setAddModalOpen,
  setUploadData,
  setSourceDeviceID,
  setSystemMenus,
} from "./sourceSlice";

import simaPro, {
  fetchVersionById,
  fetchCategoryById,
  fetchTypeById,
  setVersionID,
  setCategoryId,
  setTypeId,
  setVersionList,
  setCategoryList,
  setTypeList,
  fetchCategorySelectById,
} from "./simaProSlice";

const pageInfoPersistConfig = {
  key: "pageInfo",
  storage: storage,
};

const rootReducer = combineReducers({
  project,
  source,
  pageInfo: persistReducer(pageInfoPersistConfig, pageInfo),
  user,
  analyse,
  simaPro,
  sbtiPackage,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["source", "analyse", "simaPro"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

// 向外暴露管理對象 store
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

let persistor = persistStore(store);

// 統一向外爆露 actions
export {
  getLCA,
  clearLCA,
  changeLCAStatusToVerify,
  changeRiskStatusToVerify,
  setAntdLangage,
  fetchLangByKey,
  getSBTi,
  setBaseYearLCA,
  setBarMenu,
  setDataSource,
  setAddModalOpen,
  setUploadData,
  setSourceDeviceID,
  setSystemMenus,
  setUser,
  changeUserCompany,
  setUserAuthorizationPages,
  setUserType,
  setToken,
  setSiteUser,
  setAuthFunctionList,
  setAuthUserViewList,
  setTotalData,
  setScope,
  setSourceType,
  setDeviceData,
  setSelectedDate,
  setCompareFabs,
  setCompareYear,
  fetchVersionById,
  fetchCategoryById,
  fetchTypeById,
  setVersionID,
  setCategoryId,
  setTypeId,
  setVersionList,
  setCategoryList,
  setTypeList,
  fetchCategorySelectById,
};

// 向外爆露儲存庫
export { store, persistor };
