import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import {
  Layout,
  Menu,
  ConfigProvider,
  Avatar,
  Dropdown,
  Space,
  Button,
  Image,
} from "antd";
import {
  DownOutlined,
  LogoutOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import zh_tw from "antd/es/locale/zh_TW";
import zh_cn from "antd/es/locale/zh_CN";
import en_us from "antd/es/locale/en_US";
import vi_vn from "antd/es/locale/vi_VN";
import axios from "axios";
import { customerThemeToken } from "./customerTheme";
import { fetchLangByKey } from "@STORE";
import {
  navigateItemList,
  LOGOUT_API,
  BASEURL,
  GET_AUTHFUNCTION_API,
} from "@CON";
import BreadCrumb from "@COM/BreadCrumb";
import Logo from "@ASSET/logo-light.png";
import VersionFooter from "@COM/VersionFooter";
import "./App.scss";
import {
  setUser,
  setUserAuthorizationPages,
  setUserType,
  setSiteUser,
  setToken,
  setAuthFunctionList,
} from "@STORE";

const { Header, Content } = Layout;
let LANG = JSON.parse(localStorage.getItem("LANG"));
let antdLang = {
  en_us: en_us,
  zh_tw: zh_tw,
  zh_cn: zh_cn,
  vi_vn: vi_vn,
};

const App = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const {
    pageInfo: { antdLanguage, barMenu },
    project: { LCAInfo } = {}, // 解構 LCAInfo，但不直接賦值空物件
    user: {
      userInfo,
      authorizationPages: { userViewList, functionList },
    },
  } = useSelector((state) => state);

  // 若 LCAInfo 為空或 null，則給 ID 設置默認值 null
  const ID = LCAInfo ? LCAInfo.ID : null;

  console.log("ID", ID);

  useEffect(() => {
    i18n.changeLanguage(antdLanguage);
  }, [antdLanguage]);

  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    setSelectedKeys([barMenu]);
  }, [barMenu]);

  const logout = async () => {
    // 登出時清空 cookie 及 redux 內所有資料
    const { result } = await axios.get(LOGOUT_API);
    if (result === "success") {
      sessionStorage.clear();
      dispatch(setUserType(""));
      dispatch(setSiteUser(""));
      dispatch(setToken(null));
      dispatch(setUser(null));
      dispatch(
        setUserAuthorizationPages({
          userViewList: [],
          categoryList: [],
          authorizationPages: [],
          adminList: [],
          functionList: [],
        })
      );
      navigate("/login");
    }
  };

  const langList = LANG.map(({ key, title }) => ({
    label: (
      <Button
        type="link"
        key={key}
        onClick={() => dispatch(fetchLangByKey(key.replace("_", "-")))}
      >
        {title}
      </Button>
    ),
    key: key.replace("_", "-"),
    value: antdLang[key],
  }));

  const getLCAFunctionList = async () => {
    // 如果 ID 為 null，則將其設為 0
    const LCAID = ID === null ? 0 : ID;
    // 使用更新後的 LCAID 發送請求
    const { result, data } = await axios.get(
      `${GET_AUTHFUNCTION_API}?LCAID=${LCAID}`
    );
    if (result === "success") {
      dispatch(setAuthFunctionList(data));
    }
  };

  useEffect(() => {
    getLCAFunctionList();
  }, [ID]);

  return (
    <ConfigProvider
      csp={{ nonce: "2726c7f26c" }}
      locale={langList.find(({ key }) => key === antdLanguage)?.value}
      theme={customerThemeToken}
    >
      <Layout className="min-vh-100">
        <Header
          className="d-flex justify-content-between"
          style={{ height: "60px" }}
        >
          <div className="d-flex justify-content-start align-items-center h-100 w-50">
            <Link to="/">
              {userInfo?.Company.LogoUrl ? (
                <Image
                  height={35}
                  src={BASEURL + userInfo?.Company.LogoUrl}
                  alt="logo"
                  preview={false}
                />
              ) : null}
            </Link>
            <Menu
              className="h-100 w-75 ms-4 custom_menu"
              theme="dark"
              mode="horizontal"
              selectedKeys={selectedKeys}
              items={navigateItemList(
                functionList.find(({ Url }) => Url === "functionSetting")
                  ?.IsView
              )}
            />
          </div>

          <div className="d-flex align-items-center">
            {functionList.find(({ Url }) => Url === "totalSearchPlatform")
              ?.IsView ? (
              <Button type="primary" className="bg-info me-3">
                <Link to="/advisercompare">{t("Project.compare")}</Link>
              </Button>
            ) : (
              <></>
            )}

            <Dropdown
              menu={{
                items: [
                  {
                    key: "logout",
                    label: (
                      <Button
                        type="link"
                        icon={<LogoutOutlined />}
                        className="d-flex justify-content-evenly align-items-center text-dark w-100"
                        onClick={logout}
                      >
                        {t("User.Logout")}
                      </Button>
                    ),
                  },
                ],
              }}
            >
              <Space className="d-flex justify-content-between align-items-center h-100 me-3">
                <Avatar
                  src={`https://api.dicebear.com/7.x/thumbs/svg?seed=${
                    userInfo?.Name || "defaultSeed"
                  }`}
                  size={40}
                />
                <span className="text-white fs-6">{userInfo?.Name}</span>
                <DownOutlined className="text-white" />
              </Space>
            </Dropdown>
            <Dropdown
              menu={{
                items: langList,
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <GlobalOutlined className="fs-5 text-white" />
              </a>
            </Dropdown>
          </div>
        </Header>
        <Content
          style={{
            padding: "10px 50px",
          }}
        >
          <div className="d-sm-flex align-items-center justify-content-between">
            <nav aria-label="breadcrumb">
              <BreadCrumb />
            </nav>
          </div>
          <Layout>
            <Content>
              <Outlet />
            </Content>
          </Layout>
        </Content>
        <VersionFooter className="align-items-end" />
      </Layout>
    </ConfigProvider>
  );
};

export default App;
