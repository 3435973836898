import React, { useEffect, useState, createContext, useRef } from "react";
import { Button } from "antd";
import CalculateGoogleMapModal from "./CalculateGoogleMapModal";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { ProFormUploadButton } from "@ant-design/pro-components";
import { BASEURL } from "@CON";

export const FormContext = createContext(null);

function CalculateGoogleMap({ form, ori, des, distance }) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalKey, setModalKey] = useState(uuidv4());
  const [referencePhotoUrl, setReferencePhotoUrl] = useState(
    form?.getFieldValue("ReferenceServicePhotoUrl") || null
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setModalKey(uuidv4());
  };

  const googleMapImg = useRef(null);
  const [imageSrc, setImageSrc] = useState("");

  return (
    <>
      <div className="d-flex flex-column">
        <Button type="primary" onClick={showModal} className="mb-3">
          Google 距離計算
        </Button>
        {imageSrc && (
          <img
            id="google-map"
            ref={googleMapImg}
            src={imageSrc}
            width="100%"
            className="my-2"
            alt=""
          />
        )}
      </div>
      <FormContext.Provider
        value={{
          form,
          ori,
          des,
          distance,
          handleCancel,
          isModalOpen,
          googleMapImg,
          setImageSrc,
          onRemove: () => {
            // 清除顯示的圖片
            form?.setFieldValue("ReferenceServicePhotoUrl", null);
            form?.setFieldValue("referencePhoto", []);
            setReferencePhotoUrl(null); // 更新狀態
            setImageSrc(""); // 清空圖片的 src
          },
        }}
      >
        <CalculateGoogleMapModal key={modalKey} />
      </FormContext.Provider>

      <>
        {/* 根據 ReferenceServicePhotoUrl 是否有值來決定是否顯示圖片 */}
        {referencePhotoUrl ? (
        <div className="d-flex flex-column">
          <img
            id="google-map"
            width="100%"
            className="my-2"
            alt="Google Map"
            src={`${BASEURL}${referencePhotoUrl}`} // 動態路徑
          />
        </div>
      ) : null}
        {/* ProFormUploadButton */}
        <ProFormUploadButton
          key={uuidv4()}
          colProps={{ md: 6 }}
          label={t("UpstreamTransport.mapPhoto")}
          name="referencePhoto"
          title={t("Buttons.Upload")}
          fieldProps={{
            onChange: ({ file }) => {
              if (file.status === "removed") {
                form?.setFieldValue("ReferenceServicePhotoUrl", null); // 清空圖片路徑
                form?.setFieldValue("referencePhoto", []); // 清空上傳檔案
              } else {
                form?.setFieldValue("referencePhoto", [file]); // 設定新上傳的檔案
              }
            },
            onRemove: (file) => {
              // 清空圖片顯示
              form?.setFieldValue("ReferenceServicePhotoUrl", null); // 清空圖片路徑
              form?.setFieldValue("referencePhoto", []); // 清空上傳檔案
              setReferencePhotoUrl(null); // 更新狀態
              setImageSrc("");
            },
            beforeUpload: () => {
              return false; // 禁止直接上傳
            },
          }}
        />
      </>
    </>
  );
}

export default CalculateGoogleMap;
