import { v4 as uuidV4 } from "uuid";
import Icon from "@ant-design/icons";
import CapitalGood from "@ASSET/icon/CapitalGood.svg"; // 上游購買資本物品
import CapitalGoodHover from "@ASSET/icon/CapitalGood-h.svg";
import upstreamEnergyEmission from "@ASSET/icon/UpstreamEnergyEmission.svg"; // 與能源相關活動
import upstreamEnergyEmissionHover from "@ASSET/icon/UpstreamEnergyEmission-h.svg";
import Energy from "@ASSET/icon/Energy.svg"; // 外購能源排放
import EnergyHover from "@ASSET/icon/Energy-h.svg";
import Disposal from "@ASSET/icon/Disposal.svg"; // 上游廢棄
import DisposalHover from "@ASSET/icon/Disposal-h.svg";
import LeasedAsset from "@ASSET/icon/LeasedAsset.svg"; // 上游租賃資產
import LeasedAssetHover from "@ASSET/icon/LeasedAsset-h.svg";
import PurchasedGood from "@ASSET/icon/PurchasedGood.svg"; // 購買商品
import PurchasedGoodHover from "@ASSET/icon/PurchasedGood-h.svg";
import Consultant from "@ASSET/icon/Consultant.svg"; // 購買服務
import ConsultantHover from "@ASSET/icon/Consultant-h.svg";
import UpstreamTransport from "@ASSET/icon/UpstreamTransport.svg"; // 上游運輸及配送
import UpstreamTransportHover from "@ASSET/icon/UpstreamTransport-h.svg";
import BusinessTravel from "@ASSET/icon/BusinessTravel.svg"; // 商務旅行
import BusinessTravelHover from "@ASSET/icon/BusinessTravel-h.svg";
import Visitor from "@ASSET/icon/Visitor.svg"; // 訪客
import VisitorHover from "@ASSET/icon/Visitor-h.svg";
import Commuting from "@ASSET/icon/Commuting.svg"; // 員工通勤
import CommutingHover from "@ASSET/icon/Commuting-h.svg";

import StationaryCombustion from "@ASSET/icon/StationaryCombustion.svg"; // 固定源
import StationaryCombustionHover from "@ASSET/icon/StationaryCombustion-h.svg";
import MobileCombustion from "@ASSET/icon/MobileCombustion.svg"; // 移動源
import MobileCombustionHover from "@ASSET/icon/MobileCombustion-h.svg";
import DirectFugitiveEmission from "@ASSET/icon/DirectFugitiveEmission.svg"; // 逸散源
import DirectFugitiveEmissionHover from "@ASSET/icon/DirectFugitiveEmission-h.svg";
import DirectProcessEmission from "@ASSET/icon/DirectProcessEmission.svg"; // 製程
import DirectProcessEmissionHover from "@ASSET/icon/DirectProcessEmission-h.svg";
import Electricity from "@ASSET/icon/Electricity.svg"; // 用電量
import ElectricityHover from "@ASSET/icon/Electricity-h.svg";
import Steam from "@ASSET/icon/Steam.svg"; // 蒸氣
import SteamHover from "@ASSET/icon/Steam-h.svg";
import Refrigerant from "@ASSET/icon/Refrigerant.svg"; // 冷媒
import RefrigerantHover from "@ASSET/icon/Refrigerant-h.svg";
import OtherCompound from "@ASSET/icon/OtherCompound.svg"; // 其他關注類
import OtherCompoundHover from "@ASSET/icon/OtherCompound-h.svg";

import UseEmission from "@ASSET/icon/UseEmission.svg"; // 下游使用銷售產品
import UseEmissionHover from "@ASSET/icon/UseEmission-h.svg";
import DownstreamDisposal from "@ASSET/icon/DownstreamDisposal.svg"; // 下銷售產品廢棄處理
import DownstreamDisposalHover from "@ASSET/icon/DownstreamDisposal-h.svg";
import Investment from "@ASSET/icon/Investment.svg"; // 投資
import InvestmentHover from "@ASSET/icon/Investment-h.svg";

import Other from "@ASSET/icon/Other.svg"; // 投資
import OtherHover from "@ASSET/icon/Other-h.svg";

import WaterUsages from "@ASSET/icon/WaterUsages.png"; // 水
import WaterUsagesHover from "@ASSET/icon/WaterUsagesHover.png";

import Risk from "@ASSET/icon/Risk.svg"; // 顯著性分析

const alterSourceIcon = (sourceList) =>
  sourceList.map(({ link, icon, hoverIcon, title, editType = "" }) => ({
    icon,
    hoverIcon,
    title,
    key: uuidV4(),
    editType,
    link,
  }));

const sourceIconListForUpstreamS3 = alterSourceIcon([
  {
    icon: CapitalGood,
    hoverIcon: CapitalGoodHover,
    title: "capitalGood",
    link: "capitalGood",
  },
  {
    icon: upstreamEnergyEmission,
    hoverIcon: upstreamEnergyEmissionHover,
    title: "upstreamEmissions",
    link: "upstreamEmissions",
  },
  {
    icon: WaterUsages,
    hoverIcon: WaterUsagesHover,
    title: "waterUsage",
    link: "waterUsage",
  },
  {
    icon: PurchasedGood,
    hoverIcon: PurchasedGoodHover,
    title: "purchasedGood",
    link: "purchasedGood",
  },
  {
    icon: LeasedAsset,
    hoverIcon: LeasedAssetHover,
    title: "upstreamLeasedAsset",
    link: "leasedAsset",
  },
  {
    icon: Consultant,
    hoverIcon: ConsultantHover,
    title: "consultant",
    link: "consultant",
  },
]);

const sourceIconListForBusinessS2UP = alterSourceIcon([
  {
    icon: UpstreamTransport,
    hoverIcon: UpstreamTransportHover,
    title: "upstreamTransport",
    link: "upstreamTransport",
  },
  {
    icon: UpstreamTransport,
    hoverIcon: UpstreamTransportHover,
    title: "disposalDownTransport",
    link: "disposalDownTransport",
  },
]);

const sourceIconListForBusinessS1 = alterSourceIcon([
  {
    icon: MobileCombustion,
    hoverIcon: MobileCombustionHover,
    title: "mobileCombustion",
    link: "mobileCombustion",
  },
  {
    icon: StationaryCombustion,
    hoverIcon: StationaryCombustionHover,
    title: "stationaryCombustion",
    link: "stationaryCombustion",
  },
  {
    icon: DirectProcessEmission,
    hoverIcon: DirectProcessEmissionHover,
    title: "directProcessEmission",
    link: "directProcessEmission",
  },
  {
    icon: DirectFugitiveEmission,
    hoverIcon: DirectFugitiveEmissionHover,
    title: "directFugitiveEmission",
    link: "directFugitiveEmission",
  },
  {
    icon: OtherCompound,
    hoverIcon: OtherCompoundHover,
    title: "otherCompound",
    link: "otherCompound",
  },

  {
    icon: Electricity,
    hoverIcon: ElectricityHover,
    title: "electricity",
    link: "electricity",
    // editType: "month"
  },
  {
    icon: Steam,
    hoverIcon: SteamHover,
    title: "steam",
    link: "steam",
    // editType: "month"
  },
  {
    icon: Visitor,
    hoverIcon: VisitorHover,
    title: "visitor",
    link: "visitor",
  },
  {
    icon: BusinessTravel,
    hoverIcon: BusinessTravelHover,
    title: "businessTravel",
    link: "businessTravel",
  },
  {
    icon: Commuting,
    hoverIcon: CommutingHover,
    title: "commuting",
    link: "commuting",
  },
  {
    icon: Disposal,
    hoverIcon: DisposalHover,
    title: "disposal",
    link: "disposal",
  },
]);

const sourceIconListForBusinessS2Down = alterSourceIcon([
  {
    icon: UpstreamTransport,
    hoverIcon: UpstreamTransportHover,
    title: "downstreamTransport",
    link: "downstreamTransport",
  },
]);

const sourceIconListForRisk = alterSourceIcon([
  {
    icon: Risk,
    hoverIcon: Risk,
    title: "Risk",
    link: "Risk",
  },
]);

const sourceIconListForDownstreamS3 = alterSourceIcon([
  {
    icon: LeasedAsset,
    hoverIcon: LeasedAssetHover,
    title: "downstreamLeasedAsset",
    link: "downLeasedAsset",
  },
  {
    icon: UseEmission,
    hoverIcon: UseEmissionHover,
    title: "useEmission",
    link: "useEmission",
  },
  {
    icon: Investment,
    hoverIcon: InvestmentHover,
    title: "investment",
    link: "investment",
  },
  {
    icon: DownstreamDisposal,
    hoverIcon: DownstreamDisposalHover,
    title: "downstreamDisposal",
    link: "downstreamDisposal",
  },
]);

const sourceIconListForOtherS3 = alterSourceIcon([
  {
    icon: Other,
    hoverIcon: OtherHover,
    title: "other",
    link: "other",
  },
]);

export {
  sourceIconListForUpstreamS3,
  sourceIconListForBusinessS1,
  sourceIconListForBusinessS2UP,
  sourceIconListForBusinessS2Down,
  sourceIconListForDownstreamS3,
  sourceIconListForOtherS3,
  sourceIconListForRisk,
};
