import { asBlob } from "html-docx-js-typescript";
import { saveAs } from "file-saver";
import { notification } from "antd";

// 將圖片URL轉換為Data URL並調整尺寸
function convertImageToDataURL(imgElement, containerWidth) {
  return new Promise((resolve, reject) => {
    const img = new Image(); // 創建一個新的圖片對象
    img.onload = function () {
      // 圖片加載完成後執行的函數
      const naturalWidth = img.naturalWidth; // 獲取圖片的自然寬度
      const naturalHeight = img.naturalHeight; // 獲取圖片的自然高度

      // 解析樣式中的寬度百分比
      let widthPercentage = 100; // 默认为100%，适用于未指定宽度的情况
      if (imgElement.style.width && imgElement.style.width.includes("%")) {
        widthPercentage = parseFloat(imgElement.style.width);
      }
      const fixedWidth = containerWidth * (widthPercentage / 100); // 計算基於容器寬度的固定寬度
      const scale = fixedWidth / naturalWidth; // 計算縮放比例
      const fixedHeight = naturalHeight * scale; // 計算等比例高度

      const canvas = document.createElement("canvas"); // 創建畫布元素
      canvas.width = fixedWidth; // 設置畵布的寬度
      canvas.height = fixedHeight; // 設置畵布的高度
      const ctx = canvas.getContext("2d"); // 獲取2D繪圖上下文
      ctx.drawImage(img, 0, 0, fixedWidth, fixedHeight); // 在畫布上繪製圖片
      const dataUrl = canvas.toDataURL("image/jpeg"); // 將畫布內容轉換為Data URL

      resolve({ dataUrl, fixedWidth, fixedHeight }); // 通過Promise返回處理後的結果
    };
    img.onerror = () => {
      // 圖片加載失敗時執行的函數
      console.error("Failed to load image", imgElement.src);
      reject(new Error("Failed to load image"));
    };
    img.src = imgElement.src; // 設置圖片源地址，觸發加載
  });
}

// 假設容器寬度為文檔寬度600
const containerWidth = 600;

// 將HTML轉換為DOCX，包括圖片處理
async function exportHtmlToDocx(htmlContent, companyName) {
  const parser = new DOMParser(); // 創建DOM解析器
  const doc = parser.parseFromString(htmlContent, "text/html"); // 將HTML字符串解析為文檔對象
  const images = doc.querySelectorAll("img"); // 獲取所有圖片元素
  const links = doc.querySelectorAll("a");
  links.forEach((link) => {
    const span = document.createElement("span");
    span.innerHTML = link.innerHTML;
    link.parentNode.replaceChild(span, link);
  });

  // 添加表格邊框樣式
  const tables = doc.querySelectorAll("table");
  tables.forEach((table) => {
    const fontSize = table.getAttribute("data-font-size") || "12pt"; // 根據自定義屬性設置字體大小
    table.style.width = "100%";
    table.style.borderCollapse = "collapse";
    table.style.fontSize = fontSize; // 設置表格字體大小
    table.style.fontFamily = "標楷體"; // 設置表格字體

    const cells = table.querySelectorAll("th, td");
    cells.forEach((cell) => {
      cell.style.border = "1px solid #000";
      cell.style.padding = "1px";
      cell.style.fontSize = fontSize;
    });
  });

  // 替換楷体為標楷體
  const elementsWithFontStyle = doc.querySelectorAll('[style*="font-family"]');
  elementsWithFontStyle.forEach((element) => {
    element.style.fontFamily = element.style.fontFamily.replace(
      "楷体",
      "標楷體"
    );
  });

  const conversions = Array.from(images).map((img) => {
    return convertImageToDataURL(img, containerWidth)
      .then(({ dataUrl, fixedWidth, fixedHeight }) => {
        img.src = dataUrl; // 更新圖片src
        img.width = fixedWidth; // 設置新的寬度
        img.height = fixedHeight; // 設置新的高度
        img.style.width = `${fixedWidth}px`; // 設置CSS宽度
        img.style.height = `${fixedHeight}px`; // 設置CSS高度
      })
      .catch(() => {
        img.remove(); // 如果圖片加載失敗則從文件中移除該圖片
      });
  });

  try {
    await Promise.all(conversions); // 等待所有圖片處理完成
    const updatedHtml = doc.body.innerHTML; // 獲取更新後的HTML

    asBlob(updatedHtml).then((blob) => {
      // 使用 companyName 動態生成檔名
      const fileName = `${companyName}-溫室氣體盤查報告書.docx`;
      saveAs(blob, fileName); // 保存為DOCX文件
    });
  } catch (error) {
    console.error("Error converting images or creating DOCX:", error); // 輸出錯誤信息
    notification.error({
      message: "錯誤",
      description: "轉換圖片或創建DOCX文件時出錯。",
    });
  }
  notification.destroy("loading");
}

export { exportHtmlToDocx };
